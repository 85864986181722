<!--
 * @Author       : JiangChao
 * @Date         : 2024-04-17 10:59:01
 * @LastEditors  : JiangChao
 * @LastEditTime : 2025-04-03 10:11:12
 * @Description  : 登录注册
-->
<template>
  <div class="container">
    <div class="tab-box">
      <div
        class="tab-item"
        :class="isActive == 0 ? 'is-active' : ''"
        @click="clickTab(0)"
      >
        {{ $t("login") }}
      </div>
      <div
        class="tab-item"
        :class="isActive == 1 ? 'is-active' : ''"
        @click="clickTab(1)"
      >
        {{ $t("register") }}
      </div>
    </div>

    <!-- 登录 -->
    <div v-show="!isRegister">
      <el-form
        :model="loginForm"
        status-icon
        ref="loginRef"
        label-width="120px"
        class="demo-ruleForm"
        autocomplete="off"
      >
        <el-form-item :label="$t('country_region')">
          <el-select
            v-model="registerForm.region"
            :placeholder="$t('please_select_a_country_or_region')"
            @change="onChange"
            style="width: 280px"
          >
            <el-option
              :label="$t(item.code)"
              :value="item.code"
              v-for="(item, index) in regionArr"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item :label="$t('account')">
          <el-input
            prefix-icon="el-icon-user"
            clearable
            v-model="loginForm.username"
            autocomplete="off"
            :placeholder="placeholderTip"
          ></el-input>
        </el-form-item> -->
        <el-form-item :label="$t('account')">
          <el-input
            prefix-icon="el-icon-user"
            clearable
            :placeholder="placeholderTip"
            v-model="loginForm.username"
          >
          </el-input>
        </el-form-item>

        <el-form-item :label="$t('password')" class="psw-input">
          <el-input
            prefix-icon="el-icon-unlock"
            :placeholder="$t('password')"
            clearable
            show-password
            :type="passwordType"
            v-model="loginOriginalPassword"
            autocomplete="off"
          >
          </el-input>
          <!-- <small>{{ $t("key_password_tip") }}</small> -->
        </el-form-item>

        <el-form-item label-width="0px">
          <el-button
            round
            class="width-100"
            type="primary"
            @click="login('loginRef')"
          >
            {{ $t("login") }}</el-button
          >
        </el-form-item>
      </el-form>

      <div style="color: #1a72f5; cursor: pointer" @click="showForgetDialog">
        {{ $t("forget_password") }}
      </div>
    </div>

    <!-- 注册 -->
    <div v-show="isRegister">
      <el-form
        :model="registerForm"
        status-icon
        ref="registerRef"
        label-width="140px"
        class="demo-ruleForm"
        autocomplete="off"
      >
        <el-form-item :label="$t('country_region')">
          <el-select
            v-model="registerForm.region"
            :placeholder="$t('please_select_a_country_or_region')"
            @change="onChange"
            style="width: 260px"
          >
            <el-option
              :label="$t(item.code)"
              :value="item.code"
              v-for="(item, index) in regionArr"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('account')">
          <el-input
            clearable
            :placeholder="placeholderTip"
            v-model="registerForm.subject"
            autocomplete="off"
          >
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('verification_code')">
          <el-input
            v-model="registerForm.verifyCode"
            autocomplete="off"
            :placeholder="$t('verification_code')"
          >
            <template #append>
              <div @click="getSMSCode" class="send">
                {{ counting ? `${timerCount}s` : $t("get_verification_code") }}
              </div>
            </template>
          </el-input>
        </el-form-item>
        <!-- 
        <el-form-item :label="$t('verification_code')" v-show="!isChina">
          <el-input
            v-model="registerForm.verifyCode"
            autocomplete="off"
            :placeholder="$t('verification_code')"
          >
            <template #append>
              <div @click="getSMSCode" class="send">
                {{ counting ? `${timerCount}s` : $t("get_verification_code") }}
              </div>
            </template>
          </el-input>
        </el-form-item> -->

        <el-form-item :label="$t('password')" class="psw-input">
          <el-input
            prefix-icon="el-icon-unlock"
            show-password
            clearable
            :type="passwordType"
            :placeholder="$t('password')"
            v-model="originalPassword"
            autocomplete="new-password"
          >
          </el-input>
          <small>{{ $t("key_password_tip") }}</small>
        </el-form-item>
        <el-form-item prop="checkPass" :label="$t('confirm_password')">
          <el-input
            prefix-icon="el-icon-unlock"
            show-password
            clearable
            :type="passwordType"
            :placeholder="$t('confirm_password')"
            v-model="originalPassword2"
            autocomplete="new-password"
          >
          </el-input>
        </el-form-item>

        <div class="agreement-box">
          <el-checkbox v-model="checked"> </el-checkbox>
          <div class="agreement">
            {{ $t("i_agree") }}
            <span @click="goAgreement">
              {{ $t("privacy_policy_and_user_agreement") }}
              <!-- {{ $t("children's_privacy_protection_statement") }} -->
            </span>
            <!-- {{ $t("and") }}
            <span>
              {{
                $t("third_party_information_sharing_and_SDK_service_list")
              }}</span
            > -->
          </div>
        </div>
        <el-form-item label-width="0px">
          <el-button
            round
            class="width-100"
            type="primary"
            @click="register('registerRef')"
            >{{ $t("register") }}</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {
  authApi,
  registerApi,
  verifyCodeRegisterApi,
  updateBaseUrl,
} from "@/api/api.js";
import { RegexUtils } from "@/utils/validate/regex";
import { encrypt } from "@/utils/RSAUtil";
import config from "@/env.config.js";

export default {
  data() {
    return {
      isDebugMode: process.env.NODE_ENV === "development",
      loginForm: {
        username: "",
        subject: "",
      },
      registerForm: {
        region: "",
        mobileNum: "",
        email: "",
        subject: "",
        verifyId: "",
      },
      passwordType: "password",
      suffixIcon: "el-icon-circle-check",
      isActive: 0,
      isRegister: false,
      timerCount: 0,
      counting: false, // 是否计时中
      interval1: null,
      yzBtnEnable: true,
      isChina: true,
      loginOriginalPassword: "",
      originalPassword: "",
      originalPassword2: "",
      checked: false,
      placeholderTip: "",
      apiDomain: "",
      currentHost: window.location.protocol + "//" + window.location.host, // 当前页面的完整URL
      // regionArr: [
      //   {
      //     code: "D_China",
      //     name: "China",
      //     value: "https://logcloud-cn.sigmawit.com",
      //     remark: "中国",
      //   },
      //   {
      //     code: "D_EUA",
      //     name: "Europe",
      //     value: "https://logcloud-eu.sigmawit.com",
      //     remark: "欧洲",
      //   },
      //   {
      //     code: "D_TEST",
      //     name: "Test",
      //     value: "http://localhost:3000",
      //     remark: "测试",
      //   },
      // ],
    };
  },
  methods: {
    login() {
      if (this.isChina && !this.loginForm.username) {
        this.$message.error(
          this.$t("please_enter_your_mobile_number_or_email_address")
        );

        return;
      }

      if (!this.isChina && !this.loginForm.username) {
        this.$message.error(this.$t("please_enter_your_email_address"));

        return;
      }
      if (!this.loginOriginalPassword) {
        this.$message.error(this.$t("password_cannot_be_empty"));

        return;
      }

      // if (!RegexUtils.checkPsw(this.loginOriginalPassword)) {
      //   return;
      // }

      let timestamp = new Date().getTime();

      let params = {
        ...this.loginForm,
        salt: timestamp,
        mobileNum: this.loginForm.subject,
        email: this.loginForm.subject,
        password: encrypt(this.loginOriginalPassword + timestamp),
      };

      authApi(params).then((r) => {
        console.log("========登录========", r);
        this.$message.success(this.$t("login_success"));

        localStorage.setItem("username", r.data.username);
        localStorage.setItem("userId", r.data.userId);
        if (r.data.timezone) {
          localStorage.setItem(
            "timeZoneName",
            r.data.timezone.code || "UTC+00:00"
          );
          localStorage.setItem("timeZoneValue", r.data.timezone.timezone || 0);
          this.$store.dispatch(
            "updateSelectedTimeZone",
            r.data.timezone.timezone || 0
          );
        }
        this.$cookies.set("token", r.data.token, "2h");
        localStorage.setItem("selectedMenuItem", 1);
        this.$store.dispatch("updateSelectedMenuItem", 1);
        this.goPages("/home/board");
      });
    },
    register() {
      if (!this.checked) {
        this.$message.error(this.$t("please_select_the_agreement_first"));
        return;
      }
      if (!this.registerForm.region) {
        this.$message.error(this.$t("please_select_country_or_region"));

        return;
      }
      if (this.isChina && !this.registerForm.subject) {
        this.$message.error(
          this.$t("please_enter_your_mobile_number_or_email_address")
        );

        return;
      }

      if (!this.isChina && !this.registerForm.subject) {
        this.$message.error(this.$t("please_enter_your_email_address"));

        return;
      }

      if (this.isChina && !this.registerForm.verifyId) {
        this.$message.error(this.$t("verification_code_cannot_be_empty"));

        return;
      }
      if (!this.isChina && !this.registerForm.verifyCode) {
        this.$message.error(this.$t("verification_code_cannot_be_empty"));

        return;
      }
      if (!this.originalPassword || !this.originalPassword2) {
        this.$message.error(this.$t("password_cannot_be_empty"));
        return;
      }

      if (!RegexUtils.checkPsw(this.originalPassword)) {
        return;
      }

      if (this.originalPassword != this.originalPassword2) {
        this.$message.error(
          this.$t("the_two_password_inputs_are_inconsistent")
        );

        return;
      }

      let timestamp = new Date().getTime();
      let params = {
        ...this.registerForm,
        salt: timestamp,
        mobileNum: this.loginForm.subject,
        email: this.loginForm.subject,
        password: encrypt(this.originalPassword + timestamp),
      };
      console.log("params: ", params);

      registerApi(params).then((r) => {
        console.log("=====", r);
        this.$message.success(this.$t("registration_successful_please_login"));
        // this.clickTab(0);
        localStorage.setItem("username", r.data.username);
        localStorage.setItem("userId", r.data.userId);
        this.$cookies.set("token", r.data.token, "2h");
        localStorage.setItem("selectedMenuItem", 1);
        this.$store.dispatch("updateSelectedMenuItem", 1);
        this.goPages("/home/board");
      });
    },
    // },
    onChange(val) {
      console.log("val: ", val);
      const index = this.regionArr.findIndex((item) => {
        return item.code === val;
      });
      console.log("index: ", index);
      // 保存选择到localStorage
      // localStorage.setItem("selectedRegion", val);

      switch (val) {
        case "D_China":
          localStorage.setItem("isChina", JSON.stringify(true));
          localStorage.setItem("selectedLanguage", "zh");
          this.$store.dispatch("updateSelectedLanguage", "zh");

          this.isChina = true;
          break;
        default:
          localStorage.setItem("isChina", JSON.stringify(false));
          localStorage.setItem("selectedLanguage", "en");
          this.$store.dispatch("updateSelectedLanguage", "en");

          this.isChina = false;
          break;
      }
      this.placeholderTip =
        this.isChina == true ? this.$t("phone_email") : this.$t("email");
      this.apiDomain = this.regionArr[index].value;
      //更换不同的接口域名
      if (this.isDebugMode) {
        updateBaseUrl("https://logcloud-eu.sigmawit.com");
      } else {
        if (this.apiDomain !== this.currentHost) {
          // 如果不同，则重定向到新的域名
          window.location.href = this.apiDomain;
        }
        updateBaseUrl(this.apiDomain);
      }
      console.log("onChange====val: ", val);
      console.log("isChina: ", this.isChina);
    },

    clearIntervalHandel() {
      clearInterval(this.interval1);
      this.yzBtnEnable = true;
      this.counting = false;
    },
    goAgreement() {
      window.open(config.baseURL + "/privacyLogcloud.html", "_blank");
    },
    getSMSCode() {
      if (!this.registerForm.region) {
        this.$message.error(this.$t("please_select_country_or_region"));

        return;
      }
      if (this.isChina && !this.registerForm.subject) {
        this.$message.error(
          this.$t("please_enter_your_mobile_number_or_email_address")
        );
        return;
      }
      if (!this.isChina && !this.registerForm.subject) {
        this.$message.error(this.$t("please_enter_your_email_address"));
        return;
      }

      if (!this.isChina && !RegexUtils.isEmail(this.registerForm.subject)) {
        this.$message.error(this.$t("the_email_format_is_incorrect"));

        return;
      }

      //  loginOriginalPassword: "",
      // originalPassword: "",
      // originalPassword2: "",
      //  if (RegexUtils.checkPsw(this.registerForm.password)) {
      //   this.$message.error(this.$t("the_email_format_is_incorrect"));
      //   return;
      // }

      // if (
      //   this.isChina &&
      //   !RegexUtils.checkMobile(this.registerForm.mobileNum)
      // ) {
      //   this.$message.error(this.$t("the_phone_number_format_is_incorrect"));
      //   return;
      // }
      // let params = `mobile=${this.mobile}`;
      let timer = 180;
      //   let toast = this.$toast.loading({
      //     message: "加载中...",
      //     forbidClick: true,
      //     duration: 0,
      //   });
      const loading = this.$loading({
        lock: true,
        text: this.$t("obtaining_verification_code"),

        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      let params = { subject: this.registerForm.subject };
      // if (this.isChina) {
      //   params = {
      //     subject: this.registerForm.subject,
      //   };
      // } else {
      //   params = {

      // };
      // }
      console.log("params: ", params);

      verifyCodeRegisterApi(params).then((res) => {
        loading.close();
        console.log("verifyCodeRegisterApi----" + JSON.stringify(res));
        if (res) {
          this.$message.success(res.message);

          this.interval1 = setInterval(() => {
            timer -= 1;
            if (timer <= 0) {
              this.clearIntervalHandel();
            } else {
              this.timerCount = timer;
              this.counting = true;
              this.yzBtnEnable = false;
            }
          }, 1000);
          this.registerForm.verifyId = res.data;
        }
      });
    },
    clickTab(idx) {
      this.isActive = idx;
      console.log("clickTab---idx---" + idx);
      switch (idx) {
        case 0:
          this.isRegister = false;
          break;
        case 1:
          this.isRegister = true;
          break;
      }
    },
    showForgetDialog() {
      this.$emit("showForgetDialog", true);
    },
    setDefaultRegion() {
      // 根据当前域名设置默认选中的地区
      // this.currentHost="https://logcloud-eu.sigmawit.com"
      console.log("this.currentHost: ", this.currentHost);
      const currentHost = this.currentHost.split("//")[1]; // 只取主机名部分
      console.log("currentHost: ", currentHost);
      console.log("this.regionArr: ", JSON.stringify(this.regionArr));

      const matchedItem = this.regionArr.find((item) => {
        console.log("item: ", JSON.stringify(item)); // 打印每个检查过的item
        return item.value.includes(currentHost);
      });
      console.log("matchedItem: ", JSON.stringify(matchedItem));
      if (matchedItem) {
        this.loginForm.region = matchedItem.code;
        this.registerForm.region = matchedItem.code;
        this.onChange(matchedItem.code); // 触发onChange事件以更新其他依赖于region的属性
      }
    },
  },

  computed: {
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
    // placeholderTip() {
    //   console.log("this.isChina: ", this.isChina);
    //   // 根据isChina的值返回不同的字符串
    //   return this.isChina == true ? this.$t("phone_email") : this.$t("email");
    // },
  },
  watch: {
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
      // 当国际化语言变化时，重新计算 placeholderTip
      this.placeholderTip =
        this.isChina == true ? this.$t("phone_email") : this.$t("email");
    },
    // 监听 regionArr 的变化
    regionArr: {
      immediate: true, // 立即执行一次
      handler(newVal) {
        if (newVal.length > 0) {
          this.setDefaultRegion();
        }
      },
    },
  },

  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;
    this.isChina = JSON.parse(localStorage.getItem("isChina"));
    console.log("this.isChina: ", this.isChina);

    this.placeholderTip =
      this.isChina == true ? this.$t("phone_email") : this.$t("email");
    this.setDefaultRegion();
    // 在页面加载时从URL获取region参数
    // const urlParams = new URLSearchParams(window.location.search);
    // console.log("urlParams: ", JSON.stringify(urlParams));
    // const regionParam = urlParams.get("region");

    // if (regionParam) {
    // this.registerForm.region = decodeURIComponent(regionParam);
    // }
  },
  props: {
    regionArr: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped lang="less">
// @import "../Login.less";
.container {
  .tab-box {
    display: flex;
    width: 400px;
    background: white;
    flex-direction: row;
    align-items: center;
    line-height: 48px;
    height: 48px;
  }
  .tab-item {
    font-size: 14px;
    width: 200px;
    text-align: center;
    justify-content: center;
    background: #edeeef;
    cursor: pointer;
  }

  .is-active {
    background-color: #86c6ff;
    font-size: 16px;
    font-weight: Normal;
    text-align: center;
    line-height: 48px;
    height: 48px;

    color: #ffffff;
  }
  .send {
    font-size: 14px;
    font-weight: Normal;
    text-align: center;
    color: #6da7e0;
    line-height: 18px;
    cursor: pointer;
  }
  .demo-ruleForm {
    margin-top: 20px;
  }
}
.width-100 {
  width: 100%;
}
.agreement-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  .agreement {
    margin-left: 10px;
    span {
      color: #1a72f4;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
.psw-input {
  /deep/.el-form-item__content {
    line-height: 20px;
  }
  small {
    font-size: 12px;
    color: red;
  }
}
</style>
